var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('h3',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-briefcase")]),_vm._v("Fornecedores ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":_vm.newProvider}},[_vm._v(" Adicionar fornecedor ")])],1),_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loadingProvider,"search":_vm.search,"headers":_vm.columns,"items":_vm.provider_list.data,"locale":"pt"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.viewProviderAdministrator(item)}}},on),[_vm._v(" mdi-badge-account-horizontal ")])]}}],null,true)},[_c('span',[_vm._v("Administrador do Fornecedor")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.editProvider(item.id)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteProviderItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}])}),_c('div',[(_vm.dialogAdministration === true)?_c('ProviderAdministratorDialogComponent',{attrs:{"provider":_vm.providerSelected},on:{"showModal":function($event){return _vm.changeStatus($event)},"showAddModal":function($event){return _vm.showAddModal($event)},"showEditModal":function($event){return _vm.showEditModal($event)},"getProviderId":function($event){return _vm.getProviderId($event)},"editStatus":function($event){return _vm.editStatus($event)}}}):_vm._e(),(_vm.dialogAddManagerStatus === true)?_c('AddManager',{attrs:{"selectedProviderId":_vm.selectedProviderId,"editStatusModal":_vm.editStatusModal},on:{"showAddModal":function($event){return _vm.showAddModal($event)}}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }